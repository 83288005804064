import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useHandleActionItem } from "../../../hooks/useHandleActionItem.js";
import InputText from "../../../components/forms/InputText.jsx";
import { apiUrl } from "../../../config/config.js";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
});

function SymbolDetails() {
	const location = useLocation();
	const navigate = useNavigate();
	const locationType = location.state?.type || "";
	const data = useMemo(() => location.state?.data || [], [location.state?.data]);
	console.log("SYMBOLS", data);

	const [itemId, setItemId] = useState("");
	const [symbol, setSymbol] = useState("");
	const [name, setName] = useState("");
	const [contractSize, setContractSize] = useState("");
	const [tickSize, setTickSize] = useState("");
	const [spread, setSpread] = useState("");
	const [minTrade, setMinTrade] = useState("");
	const [maxTrade, setMaxTrade] = useState("");
	const [margin, setMargin] = useState("");

	const { handleDelete, handleSubmit } = useHandleActionItem(data, "symbols");

	useEffect(() => {
		if (locationType === "edit" && data) {
			setItemId(data.ID || "");
			setSymbol(data.Simbolo || "");
			setName(data.Nombre || "");
			setContractSize(data["Tamaño Contrato"] || "");
			setTickSize(data["Tamaño Tick"] || "");
			setSpread(data.Spread || "");
			setMinTrade(data["Trade Minimo"] || "");
			setMaxTrade(data["Trade Maximo"] || "");
			setMargin(data["Margen"] || "");
		}
	}, [locationType, data]);

	const validateForm = () => {
		if (!symbol || !name || !contractSize || !tickSize || !spread || !minTrade || !maxTrade || !margin) {
			Toast.fire({
				icon: "error",
				title: "Todos los espacios son requeridos",
				background: "#1e2836",
			});
			return false;
		}
		return true;
	};

	const handleDeleteForm = async (event) => {
		event.preventDefault();
		Swal.fire({
			title: "¿Estás seguro?",
			text: "No podrás revertir esta acción",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sí, eliminar",
			cancelButtonText: "Cancelar",
		}).then(async (result) => {
			if (result.isConfirmed) {
				await handleDelete({ id: itemId }, "symbols");
				navigate("/symbols");
			}
		});
	};

	const handleSubmitForm = async (event) => {
		event.preventDefault();
		if (!validateForm()) return;
		await handleSubmit(locationType === "add" ? "add" : "update", "symbols", {
			symbol,
			name,
			contractSize,
			tickSize,
			spread,
			minTrade,
			maxTrade,
			margin,
			id: itemId,
		});
		navigate("/symbols");
	};

	return (
		<div>
			<form onSubmit={handleSubmitForm}>
				<h1 className="text-center">{locationType === "add" ? "Agregar símbolo" : "Actualizar símbolo"}</h1>
				<InputText label={"Símbolo"} placeholder={"WLDAUD"} value={symbol} setValue={setSymbol} />
				<InputText label={"Nombre"} placeholder={"AUD Basket"} value={name} setValue={setName} />
				<InputText label={"Tamaño Contrato"} placeholder={"1"} value={contractSize} setValue={setContractSize} />
				<InputText label={"Tamaño Tick"} placeholder={"0.001"} value={tickSize} setValue={setTickSize} />
				<InputText label={"Spread"} placeholder={"0.01"} value={spread} setValue={setSpread} />
				<InputText label={"Mínimo de Trade"} placeholder={"0.1"} value={minTrade} setValue={setMinTrade} />
				<InputText label={"Máximo de Trade"} placeholder={"20"} value={maxTrade} setValue={setMaxTrade} />
				<InputText label={"Margen"} placeholder={"20"} value={margin} setValue={setMargin} />

				<div className="mb-3 d-flex justify-content-around">
					<button type="submit" className="btn btn-outline-theme btn-lg">
						{locationType === "add" ? "Agregar" : "Actualizar"}
					</button>
					<button onClick={() => navigate("/symbols")} className="btn btn-outline-theme btn-lg">
						Atrás
					</button>

					{locationType === "edit" && (
						<button onClick={handleDeleteForm} className="btn btn-outline-danger btn-lg">
							Eliminar
						</button>
					)}
				</div>
			</form>
		</div>
	);
}

export default SymbolDetails;
