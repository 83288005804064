import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useHandleActionItem } from "../../../hooks/useHandleActionItem.js";
import InputText from "../../../components/forms/InputText.jsx";
import { apiUrl } from "../../../config/config.js";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.onmouseenter = Swal.stopTimer;
		toast.onmouseleave = Swal.resumeTimer;
	},
});

export default function CustomersGestorDetails() {
	const location = useLocation();
	const navigate = useNavigate();
	const locationType = location.state?.type || "";
	const data = useMemo(() => location.state?.data || [], [location.state?.data]);
	console.log("DATA", data);
	const [itemId, setItemId] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [accountID, setAccountID] = useState("");
	const [status, setStatus] = useState("active");
	const { handleDelete, handleSubmit } = useHandleActionItem();

	useEffect(() => {
		if (locationType === "edit" && data) {
			setItemId(data.ID || "");
			setName(data.Nombre || "");
			setEmail(data.Correo || "");
			setAccountID(data.accountID || "");
			setStatus(data.Estado || "active");
		}
	}, [locationType, data]);
	const validateForm = () => {
		if (!name || !email || !accountID || !status) {
			Toast.fire({
				icon: "error",
				title: "Todos los espacios son requeridos",
				background: "#1e2836",
			});
			return false;
		}
		return true;
	};

	const handleSubmitForm = async (event) => {
		event.preventDefault();
		try {
			if (!validateForm()) return;
			if (locationType === "add") {
				const registerData = {
					name,
					email,
					accountID,
					status,
					id: itemId,
				};
				const response = await fetch(apiUrl + "/register", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(registerData),
				});
				const responseData = await response.json();
				console.log("REGISTER RESPONSE", responseData);
				if (responseData.ok) {
					Toast.fire({
						icon: "success",
						title: responseData.message,
						background: "#1e2836",
						color: "#fff",
					});
					window.history.back();
					return true;
				} else {
					Toast.fire({
						icon: "error",
						title: responseData.message,
						color: "#fff",
						background: "#1e2836",
					});
					throw new Error(responseData.message || "Error al enviar data");
				}
			} else {
				await handleSubmit("update", "customersGestor", {
					name,
					email,
					accountID,
					status,
					id: itemId,
				});
			}
			navigate("/mt5Admin");
		} catch (error) {
			console.log("ERROR", error);
		}
	};

	const handleDeleteForm = async (event) => {
		event.preventDefault();
		if (!validateForm()) return;
		await handleDelete(itemId, "users", true);
	};

	return (
		<div>
			<form onSubmit={handleSubmitForm}>
				<h1 className="text-center">{locationType === "add" ? "Agregar Usuario" : "Actualizar Usuario"}</h1>
				<InputText label="Nombre" placeholder={"Nombre"} value={name} setValue={setName} />
				<InputText label="Email" placeholder={"Email"} value={email} setValue={setEmail} disabled={locationType === "edit"} />
				<InputText label="Account ID" placeholder={"Account ID"} value={accountID} setValue={setAccountID} />
				<div className="mb-3">
					<label className="form-label">
						Estado <span className="text-danger">*</span>
					</label>
					<select className="form-select" value={status} onChange={(e) => setStatus(e.target.value)}>
						<option value="active">Active</option>
						<option value="inactive">Inactive</option>
					</select>
				</div>
				<div className="mb-3 d-flex justify-content-around ">
					<button type="submit" className="btn btn-outline-theme btn-lg">
						{locationType === "add" ? "Agregar" : "Actualizar"}
					</button>
					<button onClick={() => navigate("/mt5Admin")} className="btn btn-outline-theme btn-lg">
						Atrás
					</button>
				</div>
			</form>
		</div>
	);
}
