import React, { useEffect, useState } from "react";
import { apiUrl } from "../../config/config";
import { useHandleActionItem } from "../../hooks/useHandleActionItem";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "./TableBody.css"; // Importa el archivo CSS
import Swal from "sweetalert2";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.onmouseenter = Swal.stopTimer;
		toast.onmouseleave = Swal.resumeTimer;
	},
});

const TableBody = ({ table }) => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState([]);
	const { handleDelete } = useHandleActionItem();
	const [shouldRefetch, setShouldRefetch] = useState(false);
	const [users, setUsers] = useState();
	const [accounts, setAccounts] = useState();
	const [selectedUser, setSelectedUser] = useState();
	const [selectedAccount, setSelectedAccount] = useState();

	// Estados para la paginación
	const [currentPage, setCurrentPage] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(20); // Estado para la cantidad de filas por página
	const [searchTerm, setSearchTerm] = useState(""); // Estado para el término de búsqueda

	useEffect(() => {
		fetchData();
		getUsers();
	}, []);

	useEffect(() => {
		if (shouldRefetch) {
			fetchData();
			setShouldRefetch(false);
		}
	}, [shouldRefetch]);

	useEffect(() => {
		if (data.length > 0) {
			setIsLoading(false);
		}
	}, [data]);

	const handleAddAccounts = (send) => {
		console.log("SEND", `/${table}Details`, send);
		navigate(`/${table}Details`, { state: send });
	};

	const doDelete = (item) => {
		console.log("doDelete", item);
		const data = {
			userId: item["ID Usuario"] ? item["ID Usuario"] : "",
			id: item.ID ? item.ID : "",
			accountId: item["ID Cuenta"] ? item["ID Cuenta"] : "",
			amount: item.Monto ? item.Monto : "",
		};
		const res = handleDelete(data, table);
		if (res) {
			setTimeout(() => {
				setShouldRefetch(true);
			}, 1000);
		}
	};

	const fetchData = async () => {
		setIsLoading(true);
		try {
			const response = await fetch(apiUrl + "/getList", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ table: table }),
			});
			if (response.ok) {
				let data = await response.json();
				data.type = "add";
				data = data.map((item) => {
					const { country, ...rest } = item;
					return rest;
				});

				// Traducir los nombres de las claves al español
				const translatedData = data.map((item) => {
					const translatedItem = {};
					for (const key in item) {
						let translatedKey;
						switch (key) {
							case "id":
								translatedKey = "ID";
								break;
							case "userId":
								translatedKey = "ID Usuario";
								break;
							case "type":
								translatedKey = "Tipo";
								break;
							case "amount":
								translatedKey = "Monto";
								break;
							case "status":
								translatedKey = "Estado";
								break;
							case "createdAt":
								translatedKey = "Creado";
								break;
							case "traderId":
								translatedKey = "Trader";
								break;
							case "accountId":
								translatedKey = "ID Cuenta";
								break;
							case "name":
								translatedKey = "Nombre";
								break;
							case "email":
								translatedKey = "Correo";
								break;
							case "currency":
								translatedKey = "Moneda";
								break;
							case "date":
								translatedKey = "Creado";
								break;
							case "purchaseId":
								translatedKey = "Compra";
								break;
							case "ticketId":
								translatedKey = "Ticket";
								break;
							case "volume":
								translatedKey = "Volumen";
								break;
							case "price":
								translatedKey = "Precio";
								break;
							case "stopLoss":
								translatedKey = "Stop Loss";
								break;
							case "takeProfit":
								translatedKey = "Take Profit";
								break;
							case "profit":
								translatedKey = "Profit";
								break;
							case "percent":
								translatedKey = "Porcentaje";
								break;
							case "role":
								translatedKey = "Rol";
								break;
							case "balance":
								translatedKey = "Balance";
								break;
							case "country":
								translatedKey = "País";
								break;
							case "commision":
								translatedKey = "Comision";
								break;
							case "stopOut":
								translatedKey = "Cierre";
								break;
							case "leveragedAmount":
								translatedKey = "Monto Apalancado";
								break;
							case "description":
								translatedKey = "Descripcion";
								break;
							case "image":
								translatedKey = "Imagen";
								break;
							case "transactionId":
								translatedKey = "ID Transaccion";
								break;
							case "productId":
								translatedKey = "ID Producto";
								break;
							case "lots":
								translatedKey = "Lotes";
								break;
							case "symbol":
								translatedKey = "Simbolo";
								break;
							case "entryPrice":
								translatedKey = "Precio Entrada";
								break;
							case "exitPrice":
								translatedKey = "Precio Salida";
								break;
							case "ib":
								translatedKey = "IB";
								break;
							case "ibCode":
								translatedKey = "Codigo IB";
								break;
							case "ibFollow":
								translatedKey = "Sigue IB";
								break;
							case "password":
								translatedKey = "Contraseña";
								break;
							case "contractSize":
								translatedKey = "Tamaño Contrato";
								break;
							case "tickSize":
								translatedKey = "Tamaño Tick";
								break;
							case "marginPercent":
								translatedKey = "Porcentaje Margen";
								break;
							case "swapBuy":
								translatedKey = "Swap Buy";
								break;
							case "swapSell":
								translatedKey = "Swap Sell";
								break;
							case "spread":
								translatedKey = "Spread";
								break;
							case "cryptoAddress":
								translatedKey = "Dirección USDT";
								break;
							case "minTrade":
								translatedKey = "Trade Minimo";
								break;
							case "maxTrade":
								translatedKey = "Trade Maximo";
								break;
							case "margin":
								translatedKey = "Margen";
								break;
							default:
								translatedKey = key;
						}
						translatedItem[translatedKey] = item[key];
					}
					return translatedItem;
				});
				setData(translatedData);
				setIsLoading(false);
			} else {
				console.error("Error al registrar:", response.status, response.statusText);
				setIsLoading(false);
			}
		} catch (error) {
			console.error("Error al realizar la solicitud:", error);
			setIsLoading(false);
		}
	};

	const getUsers = async () => {
		setIsLoading(true);
		try {
			const response = await fetch(apiUrl + "/getList", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ table: "users" }),
			});
			if (response.ok) {
				let data = await response.json();
				setUsers(data);
			}
		} catch (error) {
			console.error("Error al realizar la solicitud:", error);
		}
	};

	const getAccounts = async (e) => {
		e.preventDefault();
		const userId = e.target.value;
		setIsLoading(true);

		if (userId) {
			try {
				console.log("USER ID:", userId);
				const response = await fetch(apiUrl + "/getList2", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ table: "accounts", userId: userId }),
				});
				if (response.ok) {
					let data = await response.json();
					console.log("ACCOUNTS:", data);
					setAccounts(data);
				}
			} catch (error) {
				console.error("Error al realizar la solicitud:", error);
			}
		}
		setIsLoading(false);
	};

	const getPositions = async (e) => {
		e.preventDefault();
		const accountId = e.target.value;
		setIsLoading(true);
		if (accountId) {
			try {
				const response = await fetch(apiUrl + "/getList2", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ table: "positions", accountId: accountId }),
				});
				if (response.ok) {
					let data = await response.json();
					console.log("POSITIONS:", data);
					setData(data);
				}
			} catch (error) {
				console.error("Error al realizar la solicitud:", error);
			}
		}
		setIsLoading(false);
	};

	// Manejar el cambio de página
	const handlePageClick = (event) => {
		setCurrentPage(event.selected);
	};

	// Manejar el cambio de cantidad de filas por página
	const handleItemsPerPageChange = (event) => {
		setItemsPerPage(Number(event.target.value));
		setCurrentPage(0); // Reiniciar a la primera página
	};

	// Filtrar los datos en función del término de búsqueda
	const filteredData = data.filter((item) =>
		Object.entries(item).some(([key, val]) => key !== "Creado" && String(val).toLowerCase().includes(searchTerm.toLowerCase()))
	);

	// Obtener los datos para la página actual
	const offset = currentPage * itemsPerPage;
	const currentPageData = filteredData.slice(offset, offset + itemsPerPage);
	const pageCount = Math.ceil(filteredData.length / itemsPerPage);

	const handleCreateIb = async (item) => {
		try {
			const data = {
				userId: item.ID,
				name: item.Nombre,
				email: item.Correo,
			};
			const response = await fetch(apiUrl + "/createIb", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});
			console.log("IB created successfully:", response.data);
			Toast.fire({
				icon: "success",
				title: "IB Creado satisfactoriamente",
				background: "#1e2836",
			});
		} catch (error) {
			console.error("Error creating IB:", error);
		}
	};

	return (
		<div>
			<div className="table-responsive">
				<div className="input-group mb-4">
					<div className="flex-fill position-relative">
						<div className="input-group">
							<span className="input-group-text">
								<i className="fa fa-search"></i>
							</span>
							<input
								type="text"
								className="form-control ps-35px"
								placeholder="Buscar"
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
							/>
						</div>
					</div>
				</div>

				{table === "positions" && (
					<div className="input-group mb-4">
						<div className="flex-fill position-relative me-2">
							<div className="input-group">
								<span className="input-group-text">
									<i className="fa fa-user"></i>
								</span>
								<select
									className="form-control ps-35px"
									value={selectedUser ? selectedUser.id : ""}
									onChange={(e) => {
										setSelectedUser(e.target.value);
										getAccounts(e);
									}}>
									<option value="">Todos los usuarios</option>
									{users &&
										users.length > 0 &&
										users.map((user) => (
											<option key={user.id} value={user.id}>
												{user.id} - {user.name} - {user.email}
											</option>
										))}
								</select>
							</div>
						</div>
						<div className="flex-fill position-relative">
							<div className="input-group">
								<span className="input-group-text">
									<i className="fa fa-briefcase"></i>
								</span>
								<select
									className="form-control ps-35px"
									value={selectedAccount ? selectedAccount.id : ""}
									onChange={(e) => {
										setSelectedAccount(e.target.value);
										console.log("ACCOUNTTTTTT:", e.target.value);
										getPositions(e);
									}}>
									<option value="">Todas las cuentas</option>
									{accounts &&
										accounts.length > 0 &&
										accounts.map((account) => (
											<option key={account.id} value={account.id}>
												{account.id} - {account.type}
											</option>
										))}
								</select>
							</div>
						</div>
					</div>
				)}

				<div className="d-flex justify-content-between align-items-center mb-3">
					<div>
						<label htmlFor="itemsPerPage">Filas por página:</label>
						<select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
							<option value={10}>10</option>
							<option value={20}>20</option>
							<option value={50}>50</option>
							<option value={100}>100</option>
						</select>
					</div>
				</div>
				{data.length === 0 && !isLoading && (
					<div className="text-center">
						<h1>No se encuentran datos</h1>
					</div>
				)}
				{data.length === 0 && isLoading && (
					<div className="text-center">
						<div className="spinner-border text-primary" role="status">
							<span className="visually-hidden">Cargando...</span>
						</div>
					</div>
				)}
				{data.length > 0 && (
					<>
						<table className="table table-hover text-nowrap">
							<thead>
								<tr>
									{Object.keys(currentPageData[0] || {}).map((key, i) => (
										<th key={i} className="align-middle">
											{key}
										</th>
									))}
									<th className="pt-0 pb-2">Acción</th>
								</tr>
							</thead>
							{currentPageData.map((item, index) => (
								<tbody key={index}>
									<tr>
										{Object.entries(item).map(([key, value], i) => (
											<td className="align-middle" key={i}>
												{key === "Estado"
													? typeof value === "string" && value === "1"
														? "🟢"
														: typeof value === "string" && value === "0"
														? "🔴"
														: value
													: key === "Rol"
													? value === "customer"
														? "Cliente"
														: value
													: key === "Creado"
													? new Date(value).toLocaleString().replace("T", " ").replace("Z", "") // Formatear la fecha
													: key === "Contraseña"
													? "********"
													: value}
											</td>
										))}
										<td className="align-middle">
											{table === "users" && (
												<button
													onClick={() => handleCreateIb(item)}
													className="btn btn-outline-info btn-sm mr-3"
													style={{ marginRight: "0.75rem" }}>
													<i className="fa fa-user-plus"></i> {/* Puedes cambiar el ícono según tus necesidades */}
												</button>
											)}
											<button
												onClick={() => handleAddAccounts({ type: "edit", data: item })}
												className="btn btn-outline-primary btn-sm mr-3"
												style={{ marginRight: "0.75rem" }}>
												<i className="fa fa-edit"></i>
											</button>
											<button onClick={() => doDelete(item)} className="btn btn-outline-danger btn-sm">
												<i className="fa fa-trash"></i>
											</button>
										</td>
									</tr>
								</tbody>
							))}
						</table>
						<ReactPaginate
							previousLabel={<i className="fa fa-chevron-left"></i>}
							nextLabel={<i className="fa fa-chevron-right"></i>}
							pageCount={pageCount}
							onPageChange={handlePageClick}
							containerClassName={"pagination"}
							previousLinkClassName={"pagination__link"}
							nextLinkClassName={"pagination__link"}
							disabledClassName={"pagination__link--disabled"}
							activeClassName={"pagination__link--active"}
							pageClassName={"pagination__page"}
							breakClassName={"pagination__break"}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default TableBody;
